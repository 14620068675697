@use 'utils' as *;

.container{
    width: 90%;
    max-width: rem(1200);
    margin: 0 auto;
}
.main{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: rem(82) 0;
    align-items: center;
    gap: rem(32);
    
    @include breakpoint-down(small){
        display: block;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: none;
        padding: 0;
        width: 100%;
        margin: 0;
    }
}