@use 'utils' as *;

html{
    font-size: 100%;
    box-sizing: border-box;
}

*, *::before, *::after{
    box-sizing: inherit;
}

body{
    padding: 0;
    margin: 0;
    font-family: $font-khumbsans;
}