@use 'utils' as *;

.nav{
    display: flex;
    position: relative;
    align-items: center;
    padding: rem(2) 0;
    border-bottom: 1px solid rgba($color: $black, $alpha: .1);
    // justify-content: space-between;

    .btn-menu{
        display: none;
        &:hover{
            cursor: pointer;
        }
    }

    .nav_side{
        margin-left: 5%;

        .btn-close{
            display: none;
        }

        .nav_link{
            padding: rem(32) 0;
            a{
                font-size: rem(14);
                color: black;
                opacity: .65;
                padding: rem(32) 0;
                margin-right: rem(18);
                border-bottom: rem(3) solid transparent;
            }

            & a:hover{
                border-bottom: rem(3) solid $orange;
                opacity: 1;
            }
        }
    }

    .nav_cart{
        position: relative;
        margin-left: auto;
        margin-right: rem(32);
        background-color: transparent;
        border: none;
        cursor: pointer;
        .cart-count{
            position: absolute;
            color: $white;
            font-size: rem(10);
            right: 0;
            top: 0;
            padding: 0 rem(5);
            background-color: $orange;
            border-radius: 8px;
        }
    }
    .nav_profile{
        width: rem(42);
        &:hover{
            border: rem(2) solid $orange;
            border-radius: 100%;
            cursor: pointer;
        }
    }

    @include breakpoint-down(small){
        &{
            align-items: center;
            padding: rem(12) 0;
            border-bottom: none;
            gap: rem(12);
            position: static;

            .btn-menu{
                display: block;
            }
            
            .nav_side{
                z-index: 1;
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                margin: 0;
                display: none;
                // background-color: rgba($color: $black, $alpha: .75);

                .nav_side_bg{
                    z-index: -1;
                    width: 100%;
                    height: 100%;
                    background-color: rgba($color: $black, $alpha: .75);
                    position: absolute;
                }

                &.show{
                    display: block;
                }

                .btn-close{
                    display: block;
                    padding: rem(32);
                    background-color: white;
                    border: none;
                    & svg:hover{
                        cursor: pointer;
                        transform: scale(.8);
                    }
                }
                .nav_link{
                    display: flex;
                    flex-direction: column;
                    background-color: white;
                    height: 100%;
                    overflow: hidden;
                    padding: 0;
                    max-width: 60%;
                    
                    a{
                        padding: rem(16) rem(32);
                        margin: 0;
                        border: none;
                        opacity: 1;
                        font-weight: 700;

                        &:hover{
                            border: none;
                            background-color: $orange;
                            color: white;
                        }
                    }
                }
            }
            .nav_cart{
                margin-right: rem(8);
            }
            .nav_profile{
                width: rem(24);
            }
        }
    }
}