@use 'utils' as *;

.lightbox{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: rem(32);
    .previews{
        position: relative;
        .preview{
            width: 100%;
            // min-width: rem(400);
            max-width: rem(400);
            border-radius: rem(16);
            overflow: hidden;
            &:hover{
                cursor: pointer;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        .prev, .next{
            position: absolute;
            top: 45%;
            display: none;
            align-items: center;
            justify-content: center;
            width: rem(32);
            height: rem(32);
            background-color: $white;
            border-radius: 100%;
            svg{
                transform: scale(.7);
            }
            &:hover{
                cursor: pointer;
                svg *{
                    stroke: $orange;
                }
            }
        }
        .prev{
            left: 0;
            margin-left: 5%;
        }
        .next{
            right: 0;
            margin-right: 5%;
        }
        .prev.offset{
            left: -16px;
            margin: 0;
            display: flex;
        }
        .next.offset{
            right: -16px;
            margin: 0;
            display: flex;
        }
    }
    .items{
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: rem(400);
        .item{
            max-width: rem(64);
            border-radius: rem(8);
            overflow: hidden;
            img{
                width: 100%;
                object-fit: cover;
                display: block;
            }
            &:hover{
                cursor: pointer;
                img{
                    opacity: .35;
                }
            }
            &.active{
                border: 2px solid $orange;
                img{
                    opacity: .35;
                }
            }
        }
    }

    @include breakpoint-down(small){
        .previews {
            .preview{
                border-radius: 0;
                max-width: none;
                max-height: 380px;
            }
            .prev,.next{
                display: flex;
            }
        }
        .items{
            display: none;
        }
    }
}