@use 'utils' as *;

.modal{
    // display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: $black, $alpha: .75);
    display: none;

    .modal-container{
        display: flex;
        flex-direction: column;
        row-gap: rem(16);
        .btn-close{
            margin-left: auto;
            *{
                fill: $white;
            }
            &:hover{
                cursor: pointer;
                *{
                    fill: $orange;
                }
            }
        }
    }

    @include breakpoint(medium){
        &.show{
            display: flex;
        }
    }
}