html {
  font-size: 100%;
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Kumbh Sans", sans-serif;
}

h1, h2, h3 {
  margin-top: 0;
}

a, a:active, a:visited {
  text-decoration: none;
}

.container {
  width: 90%;
  max-width: 75rem;
  margin: 0 auto;
}

.main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 5.125rem 0;
  align-items: center;
  gap: 2rem;
}
@media (max-width: 44.375em) {
  .main {
    display: block;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: none;
    padding: 0;
    width: 100%;
    margin: 0;
  }
}

.nav {
  display: flex;
  position: relative;
  align-items: center;
  padding: 0.125rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.nav .btn-menu {
  display: none;
}
.nav .btn-menu:hover {
  cursor: pointer;
}
.nav .nav_side {
  margin-left: 5%;
}
.nav .nav_side .btn-close {
  display: none;
}
.nav .nav_side .nav_link {
  padding: 2rem 0;
}
.nav .nav_side .nav_link a {
  font-size: 0.875rem;
  color: black;
  opacity: 0.65;
  padding: 2rem 0;
  margin-right: 1.125rem;
  border-bottom: 0.1875rem solid transparent;
}
.nav .nav_side .nav_link a:hover {
  border-bottom: 0.1875rem solid #ff7d1a;
  opacity: 1;
}
.nav .nav_cart {
  position: relative;
  margin-left: auto;
  margin-right: 2rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.nav .nav_cart .cart-count {
  position: absolute;
  color: white;
  font-size: 0.625rem;
  right: 0;
  top: 0;
  padding: 0 0.3125rem;
  background-color: #ff7d1a;
  border-radius: 8px;
}
.nav .nav_profile {
  width: 2.625rem;
}
.nav .nav_profile:hover {
  border: 0.125rem solid #ff7d1a;
  border-radius: 100%;
  cursor: pointer;
}
@media (max-width: 44.375em) {
  .nav {
    align-items: center;
    padding: 0.75rem 0;
    border-bottom: none;
    gap: 0.75rem;
    position: static;
  }
  .nav .btn-menu {
    display: block;
  }
  .nav .nav_side {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    display: none;
  }
  .nav .nav_side .nav_side_bg {
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    position: absolute;
  }
  .nav .nav_side.show {
    display: block;
  }
  .nav .nav_side .btn-close {
    display: block;
    padding: 2rem;
    background-color: white;
    border: none;
  }
  .nav .nav_side .btn-close svg:hover {
    cursor: pointer;
    transform: scale(0.8);
  }
  .nav .nav_side .nav_link {
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 100%;
    overflow: hidden;
    padding: 0;
    max-width: 60%;
  }
  .nav .nav_side .nav_link a {
    padding: 1rem 2rem;
    margin: 0;
    border: none;
    opacity: 1;
    font-weight: 700;
  }
  .nav .nav_side .nav_link a:hover {
    border: none;
    background-color: #ff7d1a;
    color: white;
  }
  .nav .nav_cart {
    margin-right: 0.5rem;
  }
  .nav .nav_profile {
    width: 1.5rem;
  }
}

.cart {
  z-index: 3;
  position: absolute;
  right: 0;
  top: 4.75rem;
  min-height: 110px;
  min-width: 300px;
  max-width: 330px;
  box-shadow: 0 0.75rem 1.125rem 0.125rem rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 0.5rem;
  display: none;
}
.cart.show {
  display: block;
}
.cart .cart_title {
  font-weight: 700;
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.cart .cart_content {
  padding: 1rem;
}
.cart .cart_content p {
  text-align: center;
  line-height: 55px;
}
.cart .cart_content .basket .cart_item {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  margin-bottom: 1.125rem;
}
.cart .cart_content .basket .cart_item_image, .cart .cart_content .basket .cart_item svg {
  flex-shrink: 0;
}
.cart .cart_content .basket .cart_item_image {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.25rem;
  overflow: hidden;
}
.cart .cart_content .basket .cart_item_image img {
  width: 100%;
}
.cart .cart_content .basket .cart_item .values {
  color: #b6bcc8;
  font-size: 0.875rem;
}
.cart .cart_content .basket .cart_item .values .item-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}
.cart .cart_content .basket .cart_item .values .odd {
  color: black;
  font-weight: 700;
}
.cart .cart_content .basket .cart_item :last-child {
  margin-left: auto;
}
.cart .cart_content .basket .cart_item .btn-delete:hover {
  cursor: pointer;
}
@media (max-width: 44.375em) {
  .cart {
    top: 3.625rem;
    left: 0;
    max-width: none;
    margin: 0 0.5rem;
  }
}

#outside-cart {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
}
#outside-cart.show {
  display: block;
}

.basebutton {
  width: 100%;
  max-height: 2.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  padding: 0.875rem;
  font-size: 0.875rem;
  font-weight: 700;
  color: white;
  border: none;
  border-radius: 0.5rem;
  background-color: #ff7d1a;
}
.basebutton svg {
  transform: scale(0.8);
}
.basebutton svg * {
  width: 100%;
  height: 100%;
  fill: white;
}
.basebutton:hover {
  cursor: pointer;
  opacity: 0.75;
}
.basebutton.shadow {
  box-shadow: #ff7d1a 0px 25px 20px -20px;
}

.lightbox {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}
.lightbox .previews {
  position: relative;
}
.lightbox .previews .preview {
  width: 100%;
  max-width: 25rem;
  border-radius: 1rem;
  overflow: hidden;
}
.lightbox .previews .preview:hover {
  cursor: pointer;
}
.lightbox .previews .preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.lightbox .previews .prev, .lightbox .previews .next {
  position: absolute;
  top: 45%;
  display: none;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background-color: white;
  border-radius: 100%;
}
.lightbox .previews .prev svg, .lightbox .previews .next svg {
  transform: scale(0.7);
}
.lightbox .previews .prev:hover, .lightbox .previews .next:hover {
  cursor: pointer;
}
.lightbox .previews .prev:hover svg *, .lightbox .previews .next:hover svg * {
  stroke: #ff7d1a;
}
.lightbox .previews .prev {
  left: 0;
  margin-left: 5%;
}
.lightbox .previews .next {
  right: 0;
  margin-right: 5%;
}
.lightbox .previews .prev.offset {
  left: -16px;
  margin: 0;
  display: flex;
}
.lightbox .previews .next.offset {
  right: -16px;
  margin: 0;
  display: flex;
}
.lightbox .items {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 25rem;
}
.lightbox .items .item {
  max-width: 4rem;
  border-radius: 0.5rem;
  overflow: hidden;
}
.lightbox .items .item img {
  width: 100%;
  object-fit: cover;
  display: block;
}
.lightbox .items .item:hover {
  cursor: pointer;
}
.lightbox .items .item:hover img {
  opacity: 0.35;
}
.lightbox .items .item.active {
  border: 2px solid #ff7d1a;
}
.lightbox .items .item.active img {
  opacity: 0.35;
}
@media (max-width: 44.375em) {
  .lightbox .previews .preview {
    border-radius: 0;
    max-width: none;
    max-height: 380px;
  }
  .lightbox .previews .prev, .lightbox .previews .next {
    display: flex;
  }
  .lightbox .items {
    display: none;
  }
}

.modal {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: none;
}
.modal .modal-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.modal .modal-container .btn-close {
  margin-left: auto;
}
.modal .modal-container .btn-close * {
  fill: white;
}
.modal .modal-container .btn-close:hover {
  cursor: pointer;
}
.modal .modal-container .btn-close:hover * {
  fill: #ff7d1a;
}
@media (min-width: 44.3125em) {
  .modal.show {
    display: flex;
  }
}

.iteminfo {
  display: flex;
  flex-direction: column;
  row-gap: 1.125rem;
  font-size: 0.875rem;
  color: #b6bcc8;
  max-width: 25rem;
  margin: 0 auto;
}
.iteminfo .company {
  color: #ff7d1a;
  font-weight: 700;
  text-transform: uppercase;
}
.iteminfo .title {
  line-height: 2rem;
}
.iteminfo .title, .iteminfo .price-discount {
  color: black;
  font-size: 1.875rem;
  font-weight: 700;
}
.iteminfo .price {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: 1.125rem;
}
.iteminfo .price .discount {
  max-width: fit-content;
  padding: 0.0625rem 0.375rem;
  background-color: #ffede0;
  color: #ff7d1a;
  font-weight: 700;
  border-radius: 0.25rem;
}
.iteminfo .actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.iteminfo .actions .quantities {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  font-size: 1.125rem;
  font-weight: 700;
  border-radius: 0.5rem;
  height: 2.75rem;
  background-color: #f7f8fd;
  box-shadow: rgba(104, 112, 125, 0.15) 0px 2px 8px;
  color: black;
}
.iteminfo .actions .quantities div {
  padding: 1rem;
}
.iteminfo .actions .quantities .decrease, .iteminfo .actions .quantities .increase {
  color: #ff7d1a;
}
.iteminfo .actions .quantities .decrease:hover, .iteminfo .actions .quantities .increase:hover {
  cursor: pointer;
  filter: brightness(1.4);
}
@media (max-width: 44.375em) {
  .iteminfo {
    padding: 5%;
    row-gap: 0.625rem;
  }
  .iteminfo .title, .iteminfo .price-discount {
    font-size: 1.5625rem;
  }
  .iteminfo .price {
    grid-template-columns: auto auto 1fr;
    align-items: center;
  }
  .iteminfo .price .actual-price {
    margin-left: auto;
    text-decoration: line-through;
  }
  .iteminfo .actions {
    flex-direction: column;
  }
  .iteminfo .actions .quantities {
    width: 100%;
  }
}