@use 'utils' as *;

.cart{
    z-index: 3;
    position: absolute;
    right: 0;
    top: rem(76);
    min-height: 110px;
    min-width: 300px;
    max-width: 330px;
    box-shadow: 0 rem(12) rem(18) rem(2) rgba($color: $black, $alpha: .1);
    background-color: $white;
    border-radius: rem(8);
    display: none;

    &.show{
        display: block;
    }

    .cart_title{
        font-weight: 700;
        padding: rem(16);
        border-bottom: 1px solid rgba($color: $black, $alpha: .1);
    }
    .cart_content{
        padding: rem(16);
        p{
            text-align: center;
            line-height: 55px;
        }

        .basket{
            .cart_item{
                display: flex;
                align-items: center;
                gap: rem(10);
                margin-bottom: rem(18);
                &_image, svg{
                    flex-shrink: 0;
                }
                &_image{
                    width: rem(36);
                    height: rem(36);
                    border-radius: rem(4);
                    overflow: hidden;
                    img{
                        width: 100%;
                    }
                }
                .values{
                    color: $grayrish-blue;
                    font-size: rem(14);
                    .item-title{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1; /* number of lines to show */
                        -webkit-box-orient: vertical;
                    }
                    .odd{
                        color: $black;
                        font-weight: 700;
                    }
                }
                :last-child{
                    margin-left: auto;
                }
                .btn-delete:hover{
                    cursor: pointer;
                }
            }
        }
    }

    @include breakpoint-down(small){
        &{
            top: rem(58);
            left: 0;
            max-width: none;
            margin: 0 rem(8);
        }
    }
}

#outside-cart{
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;

    &.show{
        display: block;
    }
}