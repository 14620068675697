@use 'utils' as *;

.basebutton{
    width: 100%;
    max-height: rem(44);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: rem(12);
    padding: rem(14);
    font-size: rem(14);
    font-weight: 700;
    color: white;
    border: none;
    border-radius: rem(8);
    background-color: $orange;
    svg{
        transform: scale(.8);
    }
    svg * {
        width:  100%;
        height: 100%;
        fill:   $white;
    }
    &:hover{
        cursor: pointer;
        opacity: .75;
    }
    &.shadow{
        box-shadow: rgba($color: $orange, $alpha: 1) 0px 25px 20px -20px;
    }
}